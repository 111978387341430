import { resolveComponent as _resolveComponent, withModifiers as _withModifiers, withKeys as _withKeys, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "max-w-screen-md p-8" }
const _hoisted_2 = { class: "mt-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTextInput = _resolveComponent("BaseTextInput")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_BaseModal = _resolveComponent("BaseModal")!

  return (_openBlock(), _createBlock(_component_BaseModal, null, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_BaseTextInput, {
          "data-cy": "mrn-input",
          label: _ctx.primaryExternalPatientReferenceType.label,
          error: _ctx.errors.account ? _ctx.errors.account[0] : null,
          "model-value": _ctx.modelValue,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event))),
          onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(($event: any) => (_ctx.$emit('save-mrn')), ["prevent"]), ["enter"]))
        }, null, 8, ["label", "error", "model-value"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_BaseButton, {
            size: "large",
            "data-cy": "external-references-save-btn",
            onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.$emit('save-mrn')), ["prevent"]))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('platform.common.save-changes')), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_BaseButton, {
            "data-cy": "external-references-cancel-btn",
            size: "large",
            color: "ghost",
            class: "ml-4",
            onClick: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.$emit('close-account-modal')), ["prevent"]))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('platform.common.cancel')), 1)
            ]),
            _: 1
          })
        ])
      ])
    ]),
    _: 1
  }))
}