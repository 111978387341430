
import { Vue, Options } from 'vue-class-component';
import BaseModal from '@/lib/components/Modals/BaseModal.vue';
import BaseTextInput from '@/lib/components/Input/BaseTextInput.vue';
import BaseButton from '@/lib/components/Button/BaseButton.vue';
import { ExternalPatientReferenceType } from '@/models';

@Options({
  components: { BaseButton, BaseTextInput, BaseModal },
  props: {
    modelValue: {
      type: String,
      default: ''
    },
    primaryExternalPatientReferenceType: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      required: true
    }
  }
})
export default class ExternalPatientLookupMrnModal extends Vue {
  modelValue!: string;
  primaryExternalPatientReferenceType!: ExternalPatientReferenceType;
  errors!: { [key: string]: string[] };
}
