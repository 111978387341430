import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "max-w-5xl mx-auto p-10 bg-white border rounded-lg z-50" }
const _hoisted_2 = { class: "flex flex-row items-center justify-between" }
const _hoisted_3 = { class: "text-lg font-semibold text-gray-900" }
const _hoisted_4 = { class: "grid grid-cols-1 gap-y-8 gap-x-6 mt-8 md:grid-cols-6" }
const _hoisted_5 = { class: "col-span-2" }
const _hoisted_6 = { class: "text-gray-600" }
const _hoisted_7 = { class: "text-gray-900 break-all" }
const _hoisted_8 = { class: "col-span-2" }
const _hoisted_9 = { class: "text-gray-600" }
const _hoisted_10 = { class: "text-gray-900 break-all" }
const _hoisted_11 = { class: "col-span-2" }
const _hoisted_12 = { class: "text-gray-600" }
const _hoisted_13 = { class: "text-gray-900 break-all" }
const _hoisted_14 = {
  key: 0,
  class: "grid grid-cols-1 gap-y-8 gap-x-6 mt-8 md:grid-cols-6"
}
const _hoisted_15 = { class: "col-span-2" }
const _hoisted_16 = { class: "text-gray-600" }
const _hoisted_17 = { class: "text-gray-900 break-all" }
const _hoisted_18 = { class: "grid grid-cols-1 gap-y-8 gap-x-6 mt-8 md:grid-cols-6" }
const _hoisted_19 = { class: "col-span-2" }
const _hoisted_20 = { class: "text-gray-600" }
const _hoisted_21 = { class: "text-gray-900 break-all" }
const _hoisted_22 = { class: "col-span-2" }
const _hoisted_23 = { class: "text-gray-600" }
const _hoisted_24 = { class: "text-gray-900 break-all" }
const _hoisted_25 = { class: "col-span-2" }
const _hoisted_26 = { class: "text-gray-600" }
const _hoisted_27 = { class: "text-gray-900 break-all" }
const _hoisted_28 = { class: "grid grid-cols-1 gap-y-8 gap-x-6 mt-8 md:grid-cols-6" }
const _hoisted_29 = { class: "col-span-2" }
const _hoisted_30 = { class: "text-gray-600" }
const _hoisted_31 = { class: "text-gray-900 break-all" }
const _hoisted_32 = { class: "col-span-2" }
const _hoisted_33 = { class: "text-gray-600" }
const _hoisted_34 = { class: "text-gray-900 break-all" }
const _hoisted_35 = { class: "col-span-2" }
const _hoisted_36 = { class: "text-gray-600" }
const _hoisted_37 = { class: "text-gray-900 break-all" }
const _hoisted_38 = { class: "grid grid-cols-1 gap-y-8 gap-x-6 mt-8 md:grid-cols-6" }
const _hoisted_39 = { class: "col-span-6" }
const _hoisted_40 = { class: "text-gray-600" }
const _hoisted_41 = { class: "text-gray-900 break-all" }
const _hoisted_42 = { class: "grid grid-cols-1 gap-y-8 gap-x-6 mt-8 md:grid-cols-6" }
const _hoisted_43 = { class: "flex flex-start items-center space-x-2" }
const _hoisted_44 = { class: "mt-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseIcon = _resolveComponent("BaseIcon")!
  const _component_MkBadge = _resolveComponent("MkBadge")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t('platform.patient.details')), 1)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('platform.common.title')), 1),
        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.selectedPatient.title), 1)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$t('platform.patient.given-names')), 1),
        _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.selectedPatient.first_name), 1)
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, _toDisplayString(_ctx.$t('platform.patient.last-name')), 1),
        _createElementVNode("div", _hoisted_13, _toDisplayString(_ctx.selectedPatient.last_name), 1)
      ])
    ]),
    (_ctx.selectedPatient.deceased_at)
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t('custom.uhb.patient.date-of-death')), 1),
            _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.formattedDateOfDeath), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_18, [
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("div", _hoisted_20, _toDisplayString(_ctx.$t('platform.patient.gender')), 1),
        _createElementVNode("div", _hoisted_21, _toDisplayString(_ctx.gender), 1)
      ]),
      _createElementVNode("div", _hoisted_22, [
        _createElementVNode("div", _hoisted_23, _toDisplayString(_ctx.$t('platform.patient.ethnicity')), 1),
        _createElementVNode("div", _hoisted_24, _toDisplayString(_ctx.selectedPatient.ethnicity ? _ctx.selectedPatient.ethnicity.title : ''), 1)
      ]),
      _createElementVNode("div", _hoisted_25, [
        _createElementVNode("div", _hoisted_26, _toDisplayString(_ctx.$t('platform.patient.date-of-birth')), 1),
        _createElementVNode("div", _hoisted_27, _toDisplayString(_ctx.formattedDateOfBirth), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_28, [
      _createElementVNode("div", _hoisted_29, [
        _createElementVNode("div", _hoisted_30, _toDisplayString(_ctx.$t('platform.patient.mobile-number')), 1),
        _createElementVNode("div", _hoisted_31, _toDisplayString(_ctx.selectedPatient.contact_number), 1)
      ]),
      _createElementVNode("div", _hoisted_32, [
        _createElementVNode("div", _hoisted_33, _toDisplayString(_ctx.$t('custom.uhb.patient.home-number')), 1),
        _createElementVNode("div", _hoisted_34, _toDisplayString(_ctx.selectedPatient.home_number), 1)
      ]),
      _createElementVNode("div", _hoisted_35, [
        _createElementVNode("div", _hoisted_36, _toDisplayString(_ctx.$t('platform.patient.email-address')), 1),
        _createElementVNode("div", _hoisted_37, _toDisplayString(_ctx.selectedPatient.email), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_38, [
      _createElementVNode("div", _hoisted_39, [
        _createElementVNode("div", _hoisted_40, _toDisplayString(_ctx.$t('platform.common.address')), 1),
        _createElementVNode("div", _hoisted_41, _toDisplayString(_ctx.selectedPatient.fhir_address && _ctx.selectedPatient.fhir_address.text ?_ctx.selectedPatient.fhir_address.text : null), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_42, [
      _createVNode(_component_MkBadge, { class: "col-span-6" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_43, [
            _createVNode(_component_BaseIcon, {
              class: "text-2xl stroke-1.5 text-blue-900",
              height: "0.75em",
              name: "warning"
            }),
            _createElementVNode("div", _hoisted_44, _toDisplayString(_ctx.$t('custom.uhb.patient.alert-message')), 1)
          ])
        ]),
        _: 1
      })
    ])
  ]))
}