
import { Vue, Options } from 'vue-class-component';
import { BaseModal, BaseButton } from '@/lib/components';
import { IOption } from '@/lib';

@Options({
  components: { BaseModal, BaseButton },
  props: {
    patientDetails: {
      type: Array,
      default: null
    }
  }
})
export default class ExternalPatientLookupPatientDetailModal extends Vue {
  patientDetails!: Array<IOption> | null;
}
