import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "max-w-5xl p-10 mx-auto bg-white border rounded-lg mt-4 z-50" }
const _hoisted_2 = { class: "mb-2 text-lg font-semibold text-gray-900" }
const _hoisted_3 = { class: "mb-6 text-sm font-normal text-gray-500" }
const _hoisted_4 = {
  key: 0,
  class: "flex flex-row justify-between items-center p-4 border-b border-gray-300 account-item border-t"
}
const _hoisted_5 = { class: "text-base font-regular text-gray-900 mr-4" }
const _hoisted_6 = { class: "text-base font-regular text-gray-500" }
const _hoisted_7 = { class: "text-danger-500" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AccountList = _resolveComponent("AccountList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.$t('platform.patient.primary-id')), 1),
    _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('platform.patient.primary-id-description')), 1),
    _createVNode(_component_AccountList, {
      items: _ctx.mrn ? [_ctx.mrn] : [],
      "button-text": _ctx.$t('platform.patient.add-mrn'),
      "data-cy": "accounts-list",
      readonly: _ctx.isPatientLinkedToCurrentOrganisation,
      "show-add-button": !_ctx.mrnExists && _ctx.showAddMrnButton,
      "show-delete-buttons": _ctx.showEditAndDeleteMrnButtons,
      "show-edit-buttons": _ctx.showEditAndDeleteMrnButtons,
      onEdit: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('edit-mrn', $event))),
      onDelete: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('delete-mrn', $event))),
      onAddAccount: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('add-account')))
    }, null, 8, ["items", "button-text", "readonly", "show-add-button", "show-delete-buttons", "show-edit-buttons"]),
    (_ctx.showNotApplicableMrn)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createElementVNode("div", null, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.$t('platform.patient.mrn')), 1),
            _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.$t('platform.common.not-applicable')), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.errors.mrn ? _ctx.errors.mrn[0] : ''), 1)
  ]))
}