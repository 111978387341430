
import { Vue, Options } from 'vue-class-component';
import { MPIPatient } from '@/models';
import { getDobFromISOString } from '@/helpers/patient.helper';
import { BaseIcon } from '@/lib/components';
import { genderOptions } from '@/constants';

@Options({
  components: {
    BaseIcon
  },
  props: {
    selectedPatient: {
      type: Object,
      required: true
    }
  }
})
export default class ExternalPatientLookupSelectedPatientDetails extends Vue {
  selectedPatient!: MPIPatient;

  get formattedDateOfBirth(): string {
    return this.$d(getDobFromISOString(this.selectedPatient.date_of_birth), 'short');
  }

  get formattedDateOfDeath(): string {
    return this.selectedPatient.deceased_at
      ? this.$d(getDobFromISOString(this.selectedPatient.deceased_at), 'short')
      : '';
  }

  get gender(): string | undefined {
    return genderOptions().find((option) => option.value === this.selectedPatient.gender)?.label;
  }
}
