
import { Vue, Options } from 'vue-class-component';
import { IAccount } from '@/lib';
import { AccountList } from '@/lib/components';

@Options({
  components: { AccountList },
  props: {
    mrn: {
      type: Object,
      default: null
    },
    isPatientLinkedToCurrentOrganisation: {
      type: Boolean,
      required: true
    },
    errors: {
      type: Object,
      required: true
    },
    showNotApplicableMrn: {
      type: Boolean,
      default: false
    },
    showAddMrnButton: {
      type: Boolean,
      default: true
    },
    showEditAndDeleteMrnButtons: {
      type: Boolean,
      default: true
    }
  }
})
export default class ExternalPatientLookupPrimaryId extends Vue {
  mrn!: IAccount | null;
  isPatientLinkedToCurrentOrganisation!: boolean;
  showAddMrnButton!: boolean; // Linter does not recognise variable, but it's being used 👀
  showEditAndDeleteMrnButtons!: boolean;
  showNotApplicableMRN!: boolean;
  errors!: { [key: string]: string[] };

  get mrnExists(): boolean {
    return Boolean(this.mrn && this.mrn.value);
  }
}
