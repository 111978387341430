import AWS from 'aws-sdk/global';
import Kinesis from 'aws-sdk/clients/kinesis';
import * as Sentry from '@sentry/browser';
import { v4 } from 'uuid';
import { useSessionStore } from '@/stores/session.store';

export enum Pathways {
  COPD = 'COPD',
  PLATFORM = 'PLATFORM'
}

export const recordUserEvent = (event: string, pathway: Pathways, patient_id?: string | null): void => {
  AWS.config.getCredentials((err, credentials) => {
    if (err) {
      Sentry.captureException(err);
      return;
    }

    const identityId = credentials instanceof AWS.CognitoIdentityCredentials ? credentials.identityId : '';
    const streamName = process.env.VUE_APP_AWS_KINESIS_STREAM_NAME ?? '';
    const sessionStore = useSessionStore();

    const kinesis = new Kinesis({ apiVersion: '2013-12-02' });
    kinesis.putRecord(
      {
        Data: JSON.stringify({
          environment: process.env.VUE_APP_LOG_ENV,
          date_time: new Date(),
          user_id: sessionStore.currentUser?.id,
          patient_id,
          event_id: v4(),
          event_action: event,
          pathway
        }),
        PartitionKey: `partition-${identityId}`,
        StreamName: streamName
      },
      (error) => {
        Sentry.captureException(error);
      }
    );
  });
};
